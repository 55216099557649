import {Injectable} from '@angular/core';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {from, Observable} from 'rxjs';
import {ConfirmComponent} from '../modules/shared/ui/confirm/confirm.component';
import {CheckComponent} from '../modules/shared/ui/check/check.component';
import {
  ConfirmUsernamePasswordComponent
} from '../modules/shared/ui/confirm-username-password/confirm-username-password.component';
import {
  CreateNewEntityModalComponent
} from '../modules/shared/ui/create-new-entity-modal/create-new-entity-modal.component';
import {AssociationHandlerOptions} from '@kpro-software/dynamic-forms/lib/data/association-handler-options';
import {DynamicFormsApiService} from './datamanagement/dynamic-forms-api.service';
import {StaticService} from './static.service';

@Injectable({
  providedIn: 'root'
})
export class MessageBoxService {

  constructor(
    private modalService: NgbModal,
    private readonly dynamicFormsApiService: DynamicFormsApiService,
    private readonly staticService: StaticService
  ) {
  }

  public modal(component: any, data: { [key: string]: any } = {}, options: NgbModalOptions = {
    centered: true,
    keyboard: true,
    backdrop: 'static',
  }) {
    const modalRef = this.modalService.open(component, options);

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        // Alle Werte setzen
        modalRef.componentInstance[key] = data[key];
      }
    }

    // Result als Observable zurückgeben
    return {
      ref: modalRef,
      result: from(modalRef.result)
    };
  }

  public confirm(title: string, message: string, options: NgbModalOptions = {centered: true}) {
    const modal = this.modal(ConfirmComponent, {
      title,
      message
    }, options);

    return modal;
  }

  public check(title: string, message: string, options: NgbModalOptions = {centered: true}, hint: string = null) {
    const modal = this.modal(CheckComponent, {
      title,
      message,
      hint
    }, options);

    return modal;
  }

  public confirmUsernamePassword(title: string, message: string, options: NgbModalOptions = {centered: true}) {
    const modal = this.modal(ConfirmUsernamePasswordComponent, {
      title,
      message
    }, options);

    return modal;
  }

  public openCreateNewEntityModal(
    modalOptions,
    database: string,
    beforeCreate: (entity: any) => Promise<boolean>,
    saveOptions = {}
  ): Observable<any> {

    return this.modal(CreateNewEntityModalComponent, {
      options: modalOptions,
      beforeCreate,
      getOptions: (searchOptions: AssociationHandlerOptions) => {
        return this.dynamicFormsApiService.searchOptions(searchOptions, database);
      },
      getImage: (src: string) => {
        return this.staticService.getImageUrl(src);
      },
      saveOptions
    }, {size: 'xl'}).result;
  }
}
