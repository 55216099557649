import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LocaleService {
  // tslint:disable-next-line:variable-name
  private _localeId;
  public locales = ['nl', 'de', 'fr', 'en'];

  constructor(@Inject(LOCALE_ID) localeId: string) {
    localeId = localeId ? localeId.toLowerCase() : null;

    if (localeId && localeId.toLowerCase() === 'de') {
      this._localeId = 'de';
    } else {
      this._localeId = 'en';
    }
  }

  get localeId() {
    return this._localeId;
  }

  get numericLocaleId() {
    let numericLocaleId;

    switch (this._localeId) {
      case 'nl':
        numericLocaleId = 0;
        break;
      case 'de':
        numericLocaleId = 1;
        break;
      case 'fr':
        numericLocaleId = 2;
        break;
      case 'en':
        numericLocaleId = 3;
        break;
      default:
        numericLocaleId = 1;
    }

    return numericLocaleId;
  }

  public localeToLanguage(locale: string) {
    switch (locale) {
      case 'nl':
        return $localize`:@@dutch:Niederländisch`;
      case 'de':
        return $localize`:@@german:Deutsch`;
      case 'fr':
        return $localize`:@@french:Französisch`;
      case 'en':
        return $localize`:@@english:Englisch`;
      default:
        return null;
    }
  }

  public getPedigreeFrontendUrl() {
    return environment.pedigreeFrontendUrls[this.localeId];
  }

  public getResultsFrontendUrl() {
    return environment.resultsFrontendUrls[this.localeId];
  }
}
