<div class="d-flex">
  <div [ngClass]="{'left-sidebar': !isSidebarMinimized, 'minimized-left-sidebar': isSidebarMinimized}">
    <app-left-sidebar [(isSidebarMinimized)]="isSidebarMinimized" [currentRoute]="currentRoute"></app-left-sidebar>
  </div>
  <div [ngClass]="{'content-width': !isSidebarMinimized, 'content-width-with-minimized-sidebar': isSidebarMinimized}">
    <app-header></app-header>
    <div class="p-3">
      <div class="mb-2">
        <app-breadcrumbs></app-breadcrumbs>
      </div>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
