import {BehaviorSubject, forkJoin} from 'rxjs';
import {SessionService} from './app/services/session.service';
import {DynamicFormsApiService} from './app/services/datamanagement/dynamic-forms-api.service';
import {Router} from '@angular/router';
import {ManageableTables} from './app/entities/response/manageable-tables';
import {ConfigService} from './app/services/datamanagement/config.service';


export function initManageableTables(session: SessionService,
                                     dynamicFormsService: DynamicFormsApiService,
                                     router: Router,
                                     configService: ConfigService) {
  return () => {
    return new Promise((resolve, reject) => {
      if (session.accessToken && session.user) {

        forkJoin(
          {
            manageableTables: dynamicFormsService.getManageableTables(),
            config: configService.loadConfig(),
          }
        ).subscribe(res => {
          session.manageableTables = new BehaviorSubject<ManageableTables>(res.manageableTables);
          resolve(null);
        }, () => {
          session.deleteAll();
          resolve(null);
        });

      } else {
        session.deleteAll();
        resolve(null);
      }
    });
  };
}

export function cleanLocalStorage(
  session: SessionService
) {
  return () => {
    return new Promise((resolve, reject) => {
      session.clearOldCompetitionResultEntries();
      resolve(null);
    });
  };
}
