import {Component, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {DynamicFormService} from '@kpro-software/dynamic-forms';
import {DynamicFormsApiService} from '../../../../services/datamanagement/dynamic-forms-api.service';
import {BaseInput} from '@kpro-software/dynamic-forms/lib/ui/dynamic-form/base-input';
import {AssociationHandlerOptions} from '@kpro-software/dynamic-forms/lib/data/association-handler-options';
import {OptionListResponse} from '../../../../entities/response/option-list-response';
import {Observable} from 'rxjs';
import {DynamicFormsSaveService} from '../../../../services/dynamic-forms-save.service';
import {Horse} from '../../../../entities/horse';
import {SessionService} from '../../../../services/session.service';
import {ManageableTable} from '../../../../entities/response/manageable-table';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {DefaultCreateModalValues} from '../../../../constants/default-create-modal-values';
import {CreateNewEntityModalOptions} from '../../../../entities/create-new-entity-modal-options';
import {MessageBoxService} from '../../../../services/message-box.service';
import {ManageableTables} from '../../../../entities/response/manageable-tables';

@Component({
  selector: 'app-create-new-entity-modal',
  templateUrl: './create-new-entity-modal.component.html',
  styleUrls: ['./create-new-entity-modal.component.scss']
})
export class CreateNewEntityModalComponent implements OnInit {

  public options: CreateNewEntityModalOptions;
  public beforeCreate: (entity: any) => Promise<boolean>;
  public getOptions: (options: AssociationHandlerOptions) => Observable<OptionListResponse>;
  public getImage: (src: string) => string;
  public form: FormGroup;
  public inputs: BaseInput<any>[];
  public currentTable: ManageableTable;
  public currentDatabase: string;
  public saveOptions: {[key: string]: any} = {};

  constructor(
    private readonly dynamicFormsApiService: DynamicFormsApiService,
    private readonly dynamicFormsService: DynamicFormService,
    private readonly dynamicFormsSaveService: DynamicFormsSaveService,
    private readonly sessionService: SessionService,
    private readonly activeModal: NgbActiveModal,
    private readonly messageBoxService: MessageBoxService
  ) {
  }

  ngOnInit(): void {
    // Aktuelle Tabelle laden
    this.sessionService.manageableTables.subscribe(manageableTables => {
      this.setCurrentTable(manageableTables);

      this.loadTableDescription();
    });
  }

  private setCurrentTable(manageableTables: ManageableTables) {
    this.currentTable = manageableTables.pedigree.find(table => table.table === this.options.input.table);

    if (!this.currentTable) {
      this.currentTable = manageableTables.results.find(table => table.table === this.options.input.table);
      this.currentDatabase = 'results';
    } else {
      this.currentDatabase = 'pedigree';
    }
  }

  private loadTableDescription() {
    this.dynamicFormsApiService.getDescription(this.options.input.table, this.currentDatabase, this.options.input.key)
      .subscribe(response => {
        this.inputs = this.dynamicFormsService.createInputs(response);
        this.form = this.dynamicFormsService.toFormGroup(this.inputs);

        this.setDefaultValues();
        this.disableInputs();
        this.setFixedValues();
      });
  }

  private setDefaultValues() {
    // Dynamische Standardwerte für das Hinzufügen setzen (z.B. mare, wenn Nachkommen hinzugefügt werden)
    if (this.options.defaultValues) {
      for (const defaultValueKey of Object.keys(this.options.defaultValues)) {
        this.form.get(defaultValueKey).setValue(this.options.defaultValues[defaultValueKey]);
      }
    }
  }

  private disableInputs() {
    // Inputs auf disabled setzen
    if (this.options.disabledValues) {
      for (const disabledValueKeys of this.options.disabledValues) {
        this.form.get(disabledValueKeys).disable();
      }
    }
  }

  private setFixedValues() {
    // Feste Standardwerte setzen (z.B. Stute, wenn eine Mutter hinzugefügt wird)
    if (Object.keys(DefaultCreateModalValues).indexOf(this.options.input.key) !== -1) {
      for (const defaultValueKey of Object.keys(DefaultCreateModalValues[this.options.input.key])) {
        this.form.get(defaultValueKey).setValue(DefaultCreateModalValues[this.options.input.key][defaultValueKey]);
      }
    }
  }

  // Before Update wird hier nie benutzt, da das Modal nur zum Erstellen ist. Daher wird immer true zurückgegeben
  public beforeUpdate: (entity: any) => Promise<boolean> = (entity: Horse) => {
    return new Promise<boolean>((resolve) => resolve(true));
  };

  save() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    this.dynamicFormsSaveService.save(
      this.form,
      false,
      this.currentTable,
      this.dynamicFormsApiService.getControllerName(this.currentTable.table),
      this.beforeCreate,
      this.beforeUpdate,
      null,
      false,
      this.saveOptions
    ).then(result => {
      result.subscribe(response => {
        this.activeModal.close(response.entity);
      });
    });
  }

  public close() {
    this.activeModal.close(false);
  }

  public reset() {
    this.form.reset();
  }

  public openCreateModal: (options: any) => Promise<any> = (options: any) => {
    return this.messageBoxService.modal(CreateNewEntityModalComponent, {
      options,
      beforeCreate: this.beforeCreate,
      getOptions: this.getOptions,
      getImage: this.getImage,
      saveOptions: this.saveOptions
    }, {size: 'xl'}).result.toPromise().catch();
  };
}
