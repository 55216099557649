export enum ValidationPatterns {
  URL_HTTPS = 'https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,7}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)',
  IBAN = '[a-zA-Z]{2}[0-9]{2}[a-zA-Z0-9]{4}[0-9]{7}([a-zA-Z0-9]?){0,16}',
  // Prüft, ob ein String ein numerischer Wert ist (z.B. 1234 oder -12.34)
  NUMERIC = '^(?=.)([+-]?([0-9]*)(\\.([0-9]+))?)$',
  // Prüft, ob ein String ein Integer-Wert (natürliche Zahl) ist (z.B. 1,2,3,...)
  INTEGER = '^[0-9]*$',
  // Prüft, ob ein String eine boolescher Ausdruck ist
  BOOLEAN = '^(true|false|1|0)$',
  URL_GENERAL = '^((([A-Za-z]{3,9}:(?:\\/\\/)?)(?:[-;:&=\\+\\$,\\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\\+\\$,\\w]+@)[A-Za-z0-9.-]+)((?:\\/[\\+~%\\/.\\w-_]*)?\\??(?:[-\\+=&;%@.\\w_]*)#?(?:[\\w]*))?)$',
  NEW_HORSE_FEI = '^([A-Z]{3,3}[0-9]{5,5})?$',
  OLD_HORSE_FEI = '^([0-9]{3,3}[A-Z]{2,2}[0-9]{2,2})?$',
  HORSE_USEF = '^([0-9]{7,7})?$',
  RIDER_USEF = '^([0-9]{5,7})?$',
  RIDER_FEI = '^([0-9]{8,8})?$'

}
