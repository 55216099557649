import {HorseSexes} from '../enums/horse-sexes';

export const DefaultCreateModalValues = {
  sire: {
    sex: HorseSexes.STALLION
  },
  mare: {
    sex: HorseSexes.MARE
  }
};
