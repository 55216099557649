import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {ValidationPatterns} from '../constants/validation-patterns.enum';

export function riderFeiPatternValidator(data: any): ValidatorFn {
  return ((control: AbstractControl): ValidationErrors | null => {
    const fei: number = control.value;

    if (fei === null || fei.toString(10).match(ValidationPatterns.RIDER_FEI) !== null) {
      return null;
    }

    return {riderFeiPattern: $localize`:@@riderFeiPatterValidationErrorMessage:Die FEI muss aus 8 Ziffern bestehen`};
  });
}
