import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  private environment = environment;

  constructor(private toast: ToastrService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(catchError(err => {
      if (err.status === 0) {

        let subMessage = $localize`:@@errorServiceNotAvailable:Ein Service ist möglicherweise nicht verfügbar`;
        if (err.url.startsWith(this.environment.adsEndpoint)) {
          subMessage = $localize`:@@errorAdsBackendNotAvailable:Das Ads-Backend ist möglicherweise nicht verfügbar`;
        } else if (err.url.startsWith(this.environment.cmsEndpoint)) {
          subMessage = $localize`:@@errorCMSBackendNotAvailable:Das CMS-Backend ist möglicherweise nicht verfügbar'`;
        }

        this.toast.error(subMessage, $localize`:@@errorConnectionError:Verbindungsfehler`);
      }

      return throwError(err);
    }));
  }
}
