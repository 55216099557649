import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {DeafultLayoutComponent} from './layouts/deafult-layout/deafult-layout.component';
import {LeftSidebarComponent} from './layouts/deafult-layout/left-sidebar/left-sidebar.component';
import {HeaderComponent} from './layouts/deafult-layout/header/header.component';
import {SidebarItemComponent} from './layouts/deafult-layout/left-sidebar/sidebar-item/sidebar-item.component';
import {SharedModule} from './modules/shared/shared.module';
import {TokenInterceptor} from './services/token-interceptor';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {SessionService} from './services/session.service';
import {DynamicFormsApiService} from './services/datamanagement/dynamic-forms-api.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {registerLocaleData} from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import {ErrorInterceptor} from './services/error-interceptor';
import {Router} from '@angular/router';
import {cleanLocalStorage, initManageableTables} from '../app-initializer';
import {LocalStorageService} from 'ngx-store';
import {ConfigService} from './services/datamanagement/config.service';
import {DynamicFormsModule} from '@kpro-software/dynamic-forms';
import {CustomValidator} from '@kpro-software/dynamic-forms/lib/data/custom-validator';
import {horseUsefPatternValidator} from './validators/horse-usef-pattern-validator';
import {horseFeiPatternValidator} from './validators/horse-fei-pattern-validator';
import {riderFeiPatternValidator} from './validators/rider-fei-pattern-validator';
import {riderUsefPatternValidator} from './validators/rider-usef-pattern-validator';
import {SortablejsModule} from 'ngx-sortablejs';

registerLocaleData(localeDe, 'de');
registerLocaleData(localeEn, 'en');

export const validatorConfig: CustomValidator = {
  usefPattern: horseUsefPatternValidator,
  feiPatternGroupValidator: horseFeiPatternValidator,
  riderFeiPattern: riderFeiPatternValidator,
  horseUsefPattern: horseUsefPatternValidator,
  riderUsefPattern: riderUsefPatternValidator
};

@NgModule({
  declarations: [
    AppComponent,
    DeafultLayoutComponent,
    LeftSidebarComponent,
    HeaderComponent,
    SidebarItemComponent,
  ],
  imports: [
    AppRoutingModule,
    SharedModule,
    BrowserModule,
    DynamicFormsModule.forFeature(validatorConfig),
    BrowserAnimationsModule,
    SortablejsModule.forRoot({animation: 150})
  ],
  providers: [
    LocalStorageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initManageableTables,
      multi: true,
      deps: [
        SessionService,
        DynamicFormsApiService,
        Router,
        ConfigService
      ]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: cleanLocalStorage,
      multi: true,
      deps: [
        SessionService
      ]
    },
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {

}
