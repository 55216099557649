<div class="content" (click)="expandLeftSidebar()">
  <div class="logo" [ngClass]="{'minimized-logo': isSidebarMinimized}" [routerLink]="start.url">
    <div>
      <img [src]="start.image">
      <strong *ngIf="!isSidebarMinimized" class="ml-1">
        {{start.name}}
      </strong>
    </div>
    <span *ngIf="!isSidebarMinimized" (click)="minimizeSidebar(); $event.stopPropagation()" class="ml-2">
      <i class="fa fa-angle-left"></i>
    </span>
  </div>
  <div class="menu">
    <ul>
      <ng-container *ngFor="let item of items">
        <li *ngIf="!item?.disabled">
          <app-sidebar-item [isSidebarMinimized]="isSidebarMinimized" [ngClass]="{'w-100': !isSidebarMinimized}"
                            [item]="item" [currentRoute]="currentRoute"></app-sidebar-item>
        </li>
      </ng-container>
    </ul>
  </div>
</div>




