import {Injectable} from '@angular/core';
import {DatamanagementApiService} from './datamanagement-api.service';
import {Observable} from 'rxjs';
import {Breadcrumb} from '../../entities/breadcrumb';
import {AdsApiService} from '../ads/ads-api.service';
import {CmsApiServiceService} from '../content-management/cms-api-service.service';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbsService {

  constructor(private readonly datamanagementApi: DatamanagementApiService,
              private readonly adsApi: AdsApiService,
              private readonly cmsApi: CmsApiServiceService) {
  }

  public loadDatamangementBreadcrumbs(url: string): Observable<{ breadcrumbs: Breadcrumb[] }> {
    return this.datamanagementApi.post('breadcrumbs/load', {url});
  }

  public loadAdsBreadcrumb(data: string): Observable<{ breadcrumbs: Breadcrumb[] }> {
    return this.adsApi.post('breadcrumbs/load', {data});
  }

  loadCmsBreadcrumb(url: string): Observable<{ breadcrumbs: Breadcrumb[] }> {
    return this.cmsApi.post('admin/breadcrumbs/load', {url});
  }
}
