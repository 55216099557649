import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {UserApiService} from './user-service/user-api.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private readonly api: UserApiService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const accessToken = this.api.getAccessToken();

    if (!accessToken) {
      // Wenn kein Token da ist, Request nicht authentifizieren
      return next.handle(req);
    }

    const reqWithAuthToken = req.clone({
      setHeaders: {
        Authorization: 'Bearer ' + accessToken,
      }
    });

    return next.handle(reqWithAuthToken);
  }
}
