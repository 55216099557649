import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {SessionService} from '../services/session.service';
import {DynamicFormsApiService} from '../services/datamanagement/dynamic-forms-api.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private session: SessionService, private router: Router, private dynamicFormsApiService: DynamicFormsApiService) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const loggedIn = this.session.accessToken !== null && this.session.accessToken !== undefined && (this.session.user !== null && this.session.user !== undefined);

    if (!loggedIn) {
      this.session.accessToken = '';
      this.router.navigate(['login'], {queryParams: {returnUrl: state.url} });
    }

    return loggedIn;
  }
}
