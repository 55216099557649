import {FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';
import {ValidationPatterns} from '../constants/validation-patterns.enum';
import {FEI_OLD_PATTERN_ALLOWED_TO_YEAR} from '../constants/fei-old-pattern-allowed-from-year';

export function horseFeiPatternValidator(data: any): ValidatorFn {
  return ((group: FormGroup): ValidationErrors | null => {
    const fei: string = group.get('fei').value;
    const year: number = group.get('year').value;

    if (fei === null) {
      return null;
    }

    let feiMatchesPattern = false;
    if (year !== null && year <= FEI_OLD_PATTERN_ALLOWED_TO_YEAR) {
      feiMatchesPattern = fei.match(ValidationPatterns.NEW_HORSE_FEI) !== null;
    }

    if (!feiMatchesPattern) {
      feiMatchesPattern = fei.match(ValidationPatterns.OLD_HORSE_FEI) !== null;
    }

    if (!feiMatchesPattern) {

      const defaultFeiResponse = $localize`:@@horseFeiPatternValidationErrorMessage:Falsche FEI. Die Fei muss aus 3 Ziffern gefolgt von 2 Großbuchstaben gefolgt von 2 Ziffern bestehen.
            Ein Beispiel für den korrekten aufbau wäre 123AB12.`;

      const additionalInfoForOldHorses = $localize`:@@horseFeiPatternOldHorsesValidationErrorMessage: Pferde die vor dem Jahr ${FEI_OLD_PATTERN_ALLOWED_TO_YEAR + 1} geboren wurden, können außerdem aus 3 Großbuchstaben gefolgt von 5 Ziffern bestehen.
      Ein Beispiel für den korrekten aufbau wäre ABC12345.`;

      if (year !== null && year <= FEI_OLD_PATTERN_ALLOWED_TO_YEAR) {
        return {feiPatternGroupValidator: defaultFeiResponse + additionalInfoForOldHorses};
      }

      return {feiPatternGroupValidator: defaultFeiResponse};
    }
    return null;
  });
}
