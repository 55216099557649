<div class="modal-header">
  <h4 class="modal-title">{{title}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" [formGroup]="authForm">
  <h6>{{message}}</h6>
  <div class="form-group mt-4">
    <label for="username" i18n="Username @@username">
      Benutzername
    </label>
    <input class="form-control" formControlName="username" id="username">
  </div>
  <div class="form-group">
    <label for="password" i18n="Password @@password">
      Passwort
    </label>
    <input class="form-control" formControlName="password" type="password" id="password">
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-outline-dark" (click)="cancel()" i18n="Cancel @@cancel">Abbrechen</button>
  <button type="button" class="btn btn-success" [disabled]="!authForm.valid" (click)="returnData()"
          i18n="Confirm @@confirm">
    Bestätigen
  </button>
</div>
