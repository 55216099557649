import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {MessageBoxService} from './message-box.service';

@Injectable({
  providedIn: 'root'
})
export class AdBlockerService {
  private isAdBlockerEnabled = new BehaviorSubject(false);

  constructor(private messageBoxService: MessageBoxService) {
  }


  public showAdBlockerInfoIfEnabled() {
    const testAd = document.createElement('div');
    testAd.innerHTML = '&nbsp;';
    testAd.className = 'adsbox';
    document.body.appendChild(testAd);

    window.setTimeout(() => {
      if (testAd.offsetHeight === 0) {
        this.isAdBlockerEnabled.next(true);
        this.showAdBlockerInfo();

      } else {
        this.isAdBlockerEnabled.next(false);
      }
      testAd.remove();
    }, 100);
  }

  private showAdBlockerInfo() {
    this.messageBoxService.confirm(
      $localize`:@@adBlockerEnabledTitle:AdBlocker aktiviert`,
      $localize`:@@adBlockerEnabledMessage:Achtung: Sie haben einen AdBlocker aktiviert. Bitte deaktivieren Sie diesen, um die Anwendung wie vorgesehen nutzen zu können.`
    ).result.subscribe();
  }
}
