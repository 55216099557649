import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DeafultLayoutComponent} from './layouts/deafult-layout/deafult-layout.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AuthGuard} from './guards/auth.guard';


const routes: Routes = [
  {
    path: '',
    pathMatch: 'prefix',
    canActivate: [AuthGuard],
    component: DeafultLayoutComponent,
    children: [
      {
        path: 'manage',
        loadChildren: () => import('./modules/datamanagement/datamanagement.module').then(m => m.DatamanagementModule)
      },
      {
        path: 'content-management',
        loadChildren: () => import('./modules/content-management/content-management.module').then(m => m.ContentManagementModule)
      },
      {
        path: 'ads',
        loadChildren: () => import('./modules/ads/ads.module').then(m => m.AdsModule)
      },
      {
        path: 'accounting',
        loadChildren: () => import('./modules/accounting/accounting.module').then(m => m.AccountingModule)
      },
      {
        path: 'crawling',
        loadChildren: () => import('./modules/crawling/crawling.module').then(m => m.CrawlingModule)
      },
      {
        path: 'results',
        loadChildren: () => import('./modules/results/results.module').then(m => m.ResultsModule)
      },
      {
        path: 'data-import',
        loadChildren: () => import('./modules/data-import/data-import.module').then(m => m.DataImportModule)
      }
    ]
  },
  {
    path: 'login',
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
    NgbModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
