import {NgModule} from '@angular/core';
import {CommonModule, CurrencyPipe, DatePipe, DecimalPipe, PercentPipe} from '@angular/common';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {PaginationComponent} from './ui/pagination/pagination.component';
import {ConfirmComponent} from './ui/confirm/confirm.component';
import {CheckComponent} from './ui/check/check.component';
import {ToastrModule} from 'ngx-toastr';
import {EditorModule, TINYMCE_SCRIPT_SRC} from '@tinymce/tinymce-angular';
import {ArraySortPipe} from '../../pipes/array-sort.pipe';
import {OrEmptyPipe} from '../../pipes/or-empty.pipe';
import {TextSearchComponent} from './ui/text-search/text-search.component';
import {EuroPipe} from './pipes/euro.pipe';
import {AdPerformanceSortPipe} from '../../pipes/ad-performance-sort.pipe';
import {BooleanPipe} from './pipes/boolean.pipe';
import {ConfirmUsernamePasswordComponent} from './ui/confirm-username-password/confirm-username-password.component';
import {HorseGenderPipe} from './pipes/HorseGender.pipe';
import {UserGenderPipe} from './pipes/user-gender.pipe';
import {OrderTypePipe} from './pipes/order-type.pipe';
import {CommentComponent} from './ui/comment/comment.component';
import {ColumnSortComponent} from './ui/column-sort/column-sort.component';
import {SelectModalComponent} from './ui/select-modal/select-modal.component';
import {SimpleHorseSearchComponent} from './ui/simple-horse-search/simple-horse-search.component';
import {HorseSearchFormComponent} from './ui/horse-search-form/horse-search-form.component';
import {ImageComponent} from './ui/image/image.component';
import {CrawlingTaskStatusPipe} from './pipes/crawling-task-status.pipe';
import {ModalWrapperComponent} from './ui/modal-create-component/modal-wrapper.component';
import {EnumToArrayPipe} from './pipes/enum-to-array.pipe';
import {TypeaheadComponent} from './ui/typeahead/typeahead.component';
import {CompetitionResultEntryScoreStatusPipe} from './pipes/competition-result-entry-score-status.pipe';
import {FloorPercentPipe} from '../../pipes/floor-percent.pipe';
import {CrawlingTaskReportActionPipe} from '../../pipes/crawling-task-report-action.pipe';
import {BreadcrumbsComponent} from './ui/breadcrumbs/breadcrumbs.component';
import {ResultsHorseSearchComponent} from './ui/results-horse-search/results-horse-search.component';
import {ResultsRiderSearchComponent} from './ui/results-rider-search/results-rider-search.component';
import {RouterModule} from '@angular/router';
import {MultiSelectComponent} from './ui/multi-select/multi-select.component';
import {JoinPipe} from './pipes/join.pipe';
import {SimplePaginationComponent} from './ui/simple-pagination/simple-pagination.component';
import {RiderSearchFormComponent} from './ui/rider-search-form/rider-search-form.component';
import {MarkRiderNotCompletedComponent} from './ui/mark-rider-not-complete/mark-rider-not-completed.component';
import {MarkHorseAsNotCompletedComponent} from './ui/mark-horse-as-not-completed/mark-horse-as-not-completed.component';
import {HorseLinkDisplayNamePipe} from '../../pipes/horse-link-display-name.pipe';
import {HorseEditLinkPipe} from '../../pipes/horse-edit-link.pipe';
import {CreLinkDisplayNamePipe} from '../../pipes/cre-link-display-name.pipe';
import {CreEditLinkPipe} from '../../pipes/cre-edit-link.pipe';
import {
  ResultsCreateHorseComponent
} from '../crawling/ui/assignment/horse-assignment/results-create-horse/results-create-horse.component';
import {DynamicFormsModule} from '@kpro-software/dynamic-forms';
import {CreateNewEntityModalComponent} from './ui/create-new-entity-modal/create-new-entity-modal.component';
import {ResultsCountryCodePipe} from '../../pipes/results-country-code.pipe';
import {TransformerTypeTranslationPipe} from '../../pipes/transformer-type-translation.pipe';
import {ParserLevelTranslationPipe} from '../../pipes/parser-level-translation.pipe';
import {ContentTypeTranslationPipe} from '../../pipes/content-type-translation.pipe';
import {ParserTypeTranslationPipe} from '../../pipes/parser-type-translation.pipe';
import {TargetClassTranslationPipe} from '../../pipes/target-class-translation.pipe';
import {NestedParserMethodTranslationPipe} from '../../pipes/nested-parser-method-translation.pipe';
import {CrawlerTestDetailsModalComponent} from './ui/crawler-test-details-modal/crawler-test-details-modal.component';
import {
  EditCrawlerForFieldModalComponent
} from './ui/edit-crawler-for-field-modal/edit-crawler-for-field-modal.component';
import {TransfomerTableComponent} from './ui/transfomer-table/transfomer-table.component';
import {SortablejsModule} from 'ngx-sortablejs';
import {UrlBuilderTypeTranslationPipe} from '../../pipes/url-builder-type-translation.pipe';
import {
  ResultsApiAuctionsAssignmentComponent
} from './ui/results-api-assignment/results-api-auctions-assignment.component';
import {
  ResultsApiStudbookAssignmentComponent
} from './ui/results-api-studbook-assignment/results-api-studbook-assignment.component';
import {
  ResultsApiHorseBreederAssignmentComponent
} from './ui/results-api-horse-breeder-assignment/results-api-horse-breeder-assignment.component';
import {ResultsApiParemtersComponent} from './ui/results-api-paremters/results-api-paremters.component';
import {
  PedigreeSearchApiParametersComponent
} from './ui/pedigree-search-api-parameters/pedigree-search-api-parameters.component';
import {
  PedigreeListApiParametersComponent
} from './ui/pedigree-list-api-parameters/pedigree-list-api-parameters.component';
import { PedigreePedigreeApiParametersComponent } from './ui/pedigree-pedigree-api-parameters/pedigree-pedigree-api-parameters.component';
import { ConfirmReloadCompetitionsModalComponent } from './ui/confirm-reload-competitions-modal/confirm-reload-competitions-modal.component';
import { CreateDiffTaskModalComponent } from './ui/create-diff-task-modal/create-diff-task-modal.component';

@NgModule({
  declarations: [
    PaginationComponent,
    ConfirmComponent,
    CheckComponent,
    ArraySortPipe,
    OrEmptyPipe,
    TextSearchComponent,
    EuroPipe,
    BooleanPipe,
    ConfirmUsernamePasswordComponent,
    AdPerformanceSortPipe,
    HorseGenderPipe,
    UserGenderPipe,
    OrderTypePipe,
    CommentComponent,
    SelectModalComponent,
    ColumnSortComponent,
    SimpleHorseSearchComponent,
    HorseSearchFormComponent,
    ImageComponent,
    CrawlingTaskStatusPipe,
    ModalWrapperComponent,
    EnumToArrayPipe,
    TypeaheadComponent,
    CrawlingTaskReportActionPipe,
    CompetitionResultEntryScoreStatusPipe,
    FloorPercentPipe,
    BreadcrumbsComponent,
    ResultsHorseSearchComponent,
    ResultsRiderSearchComponent,
    MultiSelectComponent,
    JoinPipe,
    SimplePaginationComponent,
    RiderSearchFormComponent,
    MarkRiderNotCompletedComponent,
    MarkHorseAsNotCompletedComponent,
    HorseLinkDisplayNamePipe,
    HorseEditLinkPipe,
    CreLinkDisplayNamePipe,
    CreEditLinkPipe,
    ResultsCreateHorseComponent,
    CreateNewEntityModalComponent,
    ResultsCountryCodePipe,
    TransformerTypeTranslationPipe,
    UrlBuilderTypeTranslationPipe,
    ParserLevelTranslationPipe,
    ContentTypeTranslationPipe,
    ParserTypeTranslationPipe,
    TargetClassTranslationPipe,
    NestedParserMethodTranslationPipe,
    CrawlerTestDetailsModalComponent,
    EditCrawlerForFieldModalComponent,
    TransfomerTableComponent,
    ResultsApiAuctionsAssignmentComponent,
    ResultsApiStudbookAssignmentComponent,
    ResultsApiHorseBreederAssignmentComponent,
    ResultsApiParemtersComponent,
    PedigreeSearchApiParametersComponent,
    PedigreeListApiParametersComponent,
    PedigreePedigreeApiParametersComponent,
    ConfirmReloadCompetitionsModalComponent,
    CreateDiffTaskModalComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    TooltipModule,
    ToastrModule.forRoot(),
    EditorModule,
    RouterModule,
    DynamicFormsModule,
    SortablejsModule
  ],
  exports: [
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    TooltipModule,
    PaginationComponent,
    ConfirmComponent,
    CheckComponent,
    ToastrModule,
    EditorModule,
    ArraySortPipe,
    OrEmptyPipe,
    TextSearchComponent,
    EuroPipe,
    AdPerformanceSortPipe,
    BooleanPipe,
    HorseGenderPipe,
    UserGenderPipe,
    OrderTypePipe,
    CommentComponent,
    ColumnSortComponent,
    HorseSearchFormComponent,
    ImageComponent,
    CrawlingTaskStatusPipe,
    ModalWrapperComponent,
    EnumToArrayPipe,
    CrawlingTaskReportActionPipe,
    TypeaheadComponent,
    CompetitionResultEntryScoreStatusPipe,
    FloorPercentPipe,
    BreadcrumbsComponent,
    ResultsHorseSearchComponent,
    MultiSelectComponent,
    JoinPipe,
    SimplePaginationComponent,
    RiderSearchFormComponent,
    MarkRiderNotCompletedComponent,
    MarkHorseAsNotCompletedComponent,
    CreEditLinkPipe,
    CreLinkDisplayNamePipe,
    HorseEditLinkPipe,
    HorseLinkDisplayNamePipe,
    ResultsCreateHorseComponent,
    ResultsCountryCodePipe,
    TransformerTypeTranslationPipe,
    UrlBuilderTypeTranslationPipe,
    ParserLevelTranslationPipe,
    ContentTypeTranslationPipe,
    ParserTypeTranslationPipe,
    TargetClassTranslationPipe,
    NestedParserMethodTranslationPipe,
    TransfomerTableComponent
  ],
  providers: [
    {provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js'},
    DecimalPipe,
    PercentPipe,
    CurrencyPipe,
    DatePipe,
    CrawlingTaskStatusPipe
  ],
})
export class SharedModule {
}
