import {Injectable} from '@angular/core';
import {ApiClient} from '../api-client';
import {HttpClient} from '@angular/common/http';
import {SessionService} from '../session.service';
import {Router} from '@angular/router';
import {LocaleService} from '../locale.service';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {GenerateAuthenticationTokenResponse} from '../../entities/response/generate-authentication-token-response';

@Injectable({
  providedIn: 'root'
})
export class UserApiService extends ApiClient {

  constructor(protected http: HttpClient, protected session: SessionService, protected router: Router, protected localeService: LocaleService) {
    super(http, session, router, environment.userServiceEndpoint, localeService);
  }

  public getAccessToken() {
    return this.session.accessToken;
  }


  public logout() {
    this.session.user = null;
    this.session.accessToken = null;
    this.session.manageableTables.next({pedigree: [], results: []});

    return this.router.navigateByUrl('/login');
  }

  public generateAuthenticationToken(username: string, password: string): Observable<GenerateAuthenticationTokenResponse> {
    return this.post<GenerateAuthenticationTokenResponse>('auth/datamanagement/generate-authentication-token', {
      username,
      password,
    });
  }
}
