import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {ValidationPatterns} from '../constants/validation-patterns.enum';

export function riderUsefPatternValidator(data: any): ValidatorFn {
  return ((control: AbstractControl): ValidationErrors | null => {
    const usef: string = control.value;

    if (usef === null || usef.match(ValidationPatterns.RIDER_USEF) !== null) {
      return null;
    }

    return {horseUsefPattern: $localize`:@@riderUsefPatterValidationErrorMessage:Falsche USEF. Die USEF muss aus 5 bis 7 Ziffern bestehen`};
  });
}
