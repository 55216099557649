<div *ngIf="_item">
  <div (click)="toggleAnimationState(); navigateToUrl(_item.url, false)" class="parent _item"
       [ngClass]="{'font-weight-bold': isActive()}">
    <img [src]="_item.image">
    <span *ngIf="!isSidebarMinimized" class="nav-_item name">{{_item.name}}</span>
    <div class="w-100" *ngIf="_item.subItems && !isSidebarMinimized">
      <i class="fa fa-angle-right float-right" *ngIf="animationState === 'in'"></i>
      <i class="fa fa-angle-down float-right" *ngIf="animationState === 'out'"></i>
    </div>
  </div>
  <ul *ngIf="!isSidebarMinimized" class="submenu" [@slideInOut]="{value: animationState, params: {height: height}}">
    <li *ngFor="let sub_item of _item.subItems" (click)="navigateToUrl(sub_item.url,true)" class="item-hover"
        [ngClass]="{'font-weight-bold': currentRoute.startsWith(sub_item.url)}">
      {{sub_item.name}}
    </li>
  </ul>
</div>
