import {Injectable} from '@angular/core';
import {User} from '../entities/user';
import {BehaviorSubject} from 'rxjs';
import {SessionStorage} from '../enums/session-storage';
import {LocalStorageService} from 'ngx-store';
import {ManageableTables} from '../entities/response/manageable-tables';
import {ManageableTable} from '../entities/response/manageable-table';
import * as moment from 'moment';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  public manageableTables: BehaviorSubject<ManageableTables>;

  constructor(private localStorageService: LocalStorageService) {
    this.manageableTables = new BehaviorSubject<ManageableTables>({
      pedigree: (new Array<ManageableTable>()),
      results: (new Array<ManageableTable>()),
    });

    const tables = this.localStorageService.get(SessionStorage.MANAGEABLE_TABLES);
    if (tables) {
      this.manageableTables.next(JSON.parse(tables));
    }

    this.manageableTables.subscribe(value => this.localStorageService.set(SessionStorage.MANAGEABLE_TABLES, JSON.stringify(value)));
  }

  set user(user: User) {
    this.localStorageService.set(SessionStorage.USER, JSON.stringify(user));
  }

  get user() {
    return this.getParsedLocaleStorage(SessionStorage.USER);
  }

  set accessToken(token: string) {
    this.localStorageService.set(SessionStorage.ACCESS_TOKEN, token);
  }

  get accessToken() {
    return this.localStorageService.get(SessionStorage.ACCESS_TOKEN);
  }

  public getParsedLocaleStorage(name: string) {
    const data = this.localStorageService.get(name);
    if (data) {
      return JSON.parse(data);
    } else {
      return null;
    }
  }

  public getParsedLocalStorageWithoutExpirationDate(key: string) {
    const data = this.localStorageService.get(key);
    if (data) {
      return JSON.parse(data).value;
    } else {
      return null;
    }
  }

  public saveLocaleStorageItemWithExpirationDate(key: string, value: any) {
    const expirationDate = this.getExpirationDate();
    const parsedValue = JSON.stringify({value, expirationDate});
    this.localStorageService.set(key, parsedValue);
  }

  // Hier wird nicht this.localStorageService.clear('all') verwendet,
  // weil wir die gespeicherten CompetitionResultEntries nicht löschen wollen
  public deleteAll() {
    for (const key of this.localStorageService.keys) {
      if (!key.startsWith('ngx_' + SessionStorage.COMPETITION_RESULT_ENTRIES_PREFIX)) {
        this.localStorageService.remove(key.replace('ngx_', ''));
      }
    }
  }

  public deleteKeyFromStorage(key: string) {
    this.localStorageService.remove(key);
  }

  public existsKey(key: string): boolean {
    return this.localStorageService.get(key) !== null;
  }

  public clearOldCompetitionResultEntries(): void {
    for (const storageKey of this.localStorageService.keys) {
      if (storageKey.startsWith('ngx_' + SessionStorage.COMPETITION_RESULT_ENTRIES_PREFIX)) {
        const cleanedStorageKey = storageKey.replace('ngx_', '');
        const expirationDate = JSON.parse(this.localStorageService.get(cleanedStorageKey)).expirationDate;
        if (expirationDate < (moment()).valueOf()) {
          this.localStorageService.remove(cleanedStorageKey);
        }
      }
    }
  }

  private getExpirationDate(): number {
    return (moment().add(environment.competitionResultEntriesLocalStorageExpirationMoths, 'months')).valueOf();
  }
}
