import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';

@Component({
  selector: 'app-deafult-layout',
  templateUrl: './deafult-layout.component.html',
  styleUrls: ['./deafult-layout.component.scss']
})
export class DeafultLayoutComponent implements OnInit {

  public currentRoute = '';
  public isSidebarMinimized = false;

  constructor(private router: Router) {
  }

  ngOnInit(): void {
    this.setCurrentRoute(this.router.url);
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((val) => {
      this.setCurrentRoute(this.router.url);
    });
  }

  private setCurrentRoute(url: string) {
    this.currentRoute = url;
  }
}
