<div class="content">
  <nav class="navbar navbar-expand-lg navbar-light float-right">
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <li ngbDropdown>
          <a class="nav-link" ngbDropdownToggle id="navbarDropdown" role="button" data-toggle="dropdown"
             aria-haspopup="true" aria-ex>
            <img [src]="userItem.user">
            {{user.firstname}} {{user.lastname}}
          </a>
          <div aria-labelledby="navbarDropdown" ngbDropdownMenu>
            <a class="dropdown-item" href="#">
              <img [src]="userItem.settings" class="dropdown-icon">
              <span i18n="header | settings @@settings">Einstellungen</span>
            </a>
            <a class="dropdown-item" href="#">
              <img [src]="userItem.profile" class="dropdown-icon">
              <span i18n="header | profile @@profile">Profil</span>
            </a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" (click)="logout()">
              <img [src]="userItem.logout" class="dropdown-icon">
              <span i18n="header | logout @@logout">Logout</span>
            </a>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</div>
