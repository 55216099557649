import {Injectable} from '@angular/core';
import {DatamanagementApiService} from './datamanagement-api.service';
import {ConfigKeys} from '../../enums/config-keys';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private config: { [key: string]: any };

  constructor(private api: DatamanagementApiService) {
  }

  public loadConfig(): Observable<void> {
    return this.api.get('config/loadGeneralConfigurations').pipe(
      map(config => {
        this.config = config;
      })
    );
  }

  public getConfig(key: ConfigKeys) {

    if (key.indexOf('.') === -1) {
      return this.config[key];
    }

    const keys = key.split('.');

    let config = this.config;
    for (const keyPart of keys) {
      if (keyPart in config) {
        config = config[keyPart];
      } else {
        throw new Error('Key ' + key + 'is not a Valid Config Key');
      }
    }

    return config;
  }
}
