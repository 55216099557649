import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StaticService {
  private endpoint = environment.staticEndpoint;

  constructor() {
  }

  public getImageUrl(path: string) {
    if (path) {
      if (path.startsWith('data:image') || path.startsWith('http')) {
        return path;
      } else if (path.startsWith('/')) {
        return this.endpoint + path.substr(1, path.length - 1);
      } else {
        return this.endpoint + path;
      }
    } else {
      return null;
    }
  }
}
