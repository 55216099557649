// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  datamanagementEndpoint: 'https://frontend-de.stage.pedigree-neu.horsetelex.dev-kpro.de/data/api/',
  cmsEndpoint: 'https://frontend-de.stage.pedigree-neu.horsetelex.dev-kpro.de/cms/api/',
  horsetelexUrl: 'https://www.horsetelex.com/',
  adsEndpoint: 'https://frontend-de.stage.pedigree-neu.horsetelex.dev-kpro.de/comms/',
  staticEndpoint: 'http://static.stage.pedigree-neu.horsetelex.dev-kpro.de/',
  pedigreeBackboneEndpoint: 'https://frontend-en.stage.pedigree-neu.horsetelex.dev-kpro.de/pedigree/backbone/',
  pedigreeApiEndpoint: 'https://frontend-en.stage.pedigree-neu.horsetelex.dev-kpro.de/pedigree/api/',
  resultsApiEndpoint: 'https://frontend-de.stage.pedigree-neu.horsetelex.dev-kpro.de/results.php',
  resultsBackendUrl: 'https://frontend-en.stage.pedigree-neu.horsetelex.dev-kpro.de/results/',
  crawlingApiEndpoint: 'https://frontend-en.stage.pedigree-neu.horsetelex.dev-kpro.de/crawling/',
  horsesSearchApiEndpoint: 'https://frontend-en.stage.pedigree-neu.horsetelex.dev-kpro.de/search/',
  userServiceEndpoint: 'https://frontend-en.stage.pedigree-neu.horsetelex.dev-kpro.de/user-service/',
  blacktypeEndpoint: 'https://frontend-de.stage.pedigree-neu.horsetelex.dev-kpro.de/blacktype/render-pedigree/',
  pedigreeFrontendUrls: {
    de: 'https://frontend-de.stage.pedigree-neu.horsetelex.dev-kpro.de/',
    en: 'https://frontend-en.stage.pedigree-neu.horsetelex.dev-kpro.de/',
    fr: 'https://frontend-fr.stage.pedigree-neu.horsetelex.dev-kpro.de/',
    nl: 'https://frontend-nl.stage.pedigree-neu.horsetelex.dev-kpro.de/'
  },
  resultsFrontendUrls: {
    de: 'https://results-de.stage.pedigree-neu.horsetelex.dev-kpro.de/',
    en: 'https://results-en.stage.pedigree-neu.horsetelex.dev-kpro.de/',
    fr: 'https://results-fr.stage.pedigree-neu.horsetelex.dev-kpro.de/',
    nl: 'https://results-nl.stage.pedigree-neu.horsetelex.dev-kpro.de/'
  },
  featureFlags: {
    isCrawlingEnabled: true,
    isResultsNewModeEnabled: true,
    isCreScoreValidationEnabled: true,
  },
  adsImageFileSize: 2 * 1024 * 1024,
  competitionResultEntriesLocalStorageExpirationMoths: 3,
  overviewMaxNumberOfResults: 500
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
