<div *ngIf="form">
  <div class="modal-header">
    <h4 class="modal-title">
      {{options.input.label}}
      <span i18n="Create new entity modal | add @@createNewEntityModalAdd">hinzufügen</span>
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-tiny-form [inputFields]="inputs" [form]="form" class="mt-3" [openCreateModal]="openCreateModal"
                   [associationSearchHandler]="getOptions" [imageLoadHandler]="getImage"></app-tiny-form>
    <div class="text-right mt-2">
      <button class="btn btn-secondary mr-2" (click)="reset()"
              i18n="table row | reset @@reset">
        Zurücksetzen
      </button>
      <button class="btn btn-primary" (click)="save()" [ngClass]="{'disabled': form.invalid}"
              i18n="table row | save @@save">
        Speichern
      </button>
    </div>
  </div>
</div>
