import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {Breadcrumb} from '../../../../entities/breadcrumb';
import {BreadcrumbsService} from '../../../../services/datamanagement/breadcrumbs.service';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {

  public breadcrumbs: Breadcrumb[] = [];

  constructor(
    private readonly router: Router,
    private readonly breadcrumbsService: BreadcrumbsService
  ) {
  }

  ngOnInit(): void {
    this.loadBreadcrumb();
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.loadBreadcrumb();
    });
  }

  private loadBreadcrumb() {
    const service = this.router.url.split('/')[1];
    switch (service) {
      case 'manage':
      case 'results':
      case 'accounting':
        this.breadcrumbsService.loadDatamangementBreadcrumbs(this.router.url).subscribe(response => {
          this.breadcrumbs = response.breadcrumbs;
        });
        break;
      case 'ads':
        this.breadcrumbsService.loadAdsBreadcrumb(this.router.url).subscribe(response => {
          this.breadcrumbs = response.breadcrumbs;
        });
        break;
      case 'content-management':
        // loadCmsBreadcrumb
        this.breadcrumbsService.loadCmsBreadcrumb(this.router.url).subscribe(response => {
          this.breadcrumbs = response.breadcrumbs;
        });
        break;
      default:
        this.breadcrumbs = [];
        break;
    }

  }
}
