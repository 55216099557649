import {Component, OnInit} from '@angular/core';
import {layoutItems} from '../../../constants/layout-items';
import {SessionService} from '../../../services/session.service';
import {User} from '../../../entities/user';
import {UserApiService} from '../../../services/user-service/user-api.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public userItem = layoutItems.user;
  public user: User;

  constructor(private readonly api: UserApiService,
              private readonly session: SessionService) {
  }

  ngOnInit(): void {
    this.user = this.session.user;
  }

  logout() {
    this.api.logout();
  }

}
