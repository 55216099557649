import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {layoutItems} from '../../../constants/layout-items';
import {SessionService} from '../../../services/session.service';
import {filter} from 'rxjs/operators';
import {ManageableTables} from '../../../entities/response/manageable-tables';
import {ManageableTable} from '../../../entities/response/manageable-table';
import {SidebarItem} from '../../../entities/layout/sidebar-item';

@Component({
  selector: 'app-left-sidebar',
  templateUrl: './left-sidebar.component.html',
  styleUrls: ['./left-sidebar.component.scss']
})
export class LeftSidebarComponent implements OnInit {

  @Input()
  public currentRoute: string;

  @Input()
  public isSidebarMinimized = false;

  @Output()
  public isSidebarMinimizedChange = new EventEmitter<boolean>();

  public start = layoutItems.start;
  public items;
  manageableTables: ManageableTables;

  constructor(public session: SessionService) {
  }

  ngOnInit(): void {
    this.session.manageableTables.pipe(
      filter((value) => value.results.length > 0 || value.pedigree.length > 0)
    ).subscribe(value => {
      this.manageableTables = value;

      const predefinedItems = layoutItems.sidebarItems;
      this.buildDatamanagementItem(predefinedItems);
      this.items = predefinedItems;
    });
  }

  buildDatamanagementItem(predefinedItems: SidebarItem[]) {
    const pedigree = predefinedItems.find(item => item.url === '/manage');
    const results = predefinedItems.find(item => item.url === '/results');
    if (this.manageableTables) {

      const pedigreeTables: ManageableTable[] = this.getVisibleTables(this.manageableTables.pedigree);
      const resultTables: ManageableTable[] = this.getVisibleTables(this.manageableTables.results);

      this.setSubItems(pedigree, '/manage/', pedigreeTables);
      this.setSubItems(results, '/results/', resultTables);
    }
  }

  private getVisibleTables(tables: ManageableTable[]) {
    const visibleTables: ManageableTable[] = [];
    for (const table of tables) {
      if (Object.keys(table).indexOf('isVisible') === -1 || table.isVisible) {
        visibleTables.push(table);
      }
    }
    return visibleTables;
  }

  private setSubItems(parent: SidebarItem, urlPrefix: string, tables: ManageableTable[]) {
    const subItems = [];

    for (const table of tables) {
      subItems.push({name: table.label, url: urlPrefix + table.table});
    }
    subItems.push(...parent.subItems);
    parent.subItems = subItems;
  }

  public minimizeSidebar() {
    this.isSidebarMinimized = true;
    this.isSidebarMinimizedChange.emit(this.isSidebarMinimized);
  }

  public expandLeftSidebar() {
    this.isSidebarMinimized = false;
    this.isSidebarMinimizedChange.emit(this.isSidebarMinimized);
  }
}
