import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {SessionService} from './session.service';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {LocaleService} from './locale.service';

export abstract class ApiClient {
  protected endpoint: string;

  constructor(protected http: HttpClient,
              protected session: SessionService,
              protected router: Router,
              protected apiEndpoint: string,
              protected localeService: LocaleService) {
    this.endpoint = apiEndpoint;
  }

  public get<T>(path: string, params: {} = {}): Observable<T> {
    return this.http.get<T>(this.endpoint + path, this.getHttpOptions(params));
  }

  public post<T>(path: string, body: {}, params: {} = {}): Observable<T> {
    return this.http.post<T>(this.endpoint + path, body, this.getHttpOptions(params));
  }

  public postFile<T>(path: string, body: {}, params: {} = {}): Observable<T> {
    return this.http.post<T>(this.endpoint + path, body, this.getFileHttpOptions(params));
  }

  public put<T>(path: string, body: {}, params: {} = {}) {
    return this.http.put<T>(this.endpoint + path, body, this.getHttpOptions(params));
  }

  public delete<T>(path: string, body: {}, params: {} = {}) {
    const httpOptions = this.getHttpOptions(params);
    return this.http.delete<T>(this.endpoint + path, Object.assign(httpOptions, {body}));
  }

  private getFileHttpOptions(params: {} = {}) {
    return {
      headers: new HttpHeaders({
        Locale: this.localeService.localeId
      }),
      params: this.createParams(params)
    };
  }

  private getHttpOptions(params: {} = {}) {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Locale: this.localeService.localeId
      }),
      params: this.createParams(params)
    };
  }

  private createParams(params: {}) {
    let getParams = new HttpParams();

    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        getParams = getParams.append(key, params[key]);
      }
    }

    return getParams;
  }

}
