import {Injectable} from '@angular/core';
import {DatamanagementApiService} from './datamanagement-api.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {DynamicFormsListResponse} from '../../entities/response/dynamic-forms-list-response';
import {OptionListResponse} from '../../entities/response/option-list-response';
import {EntityResponse} from '../../entities/response/entity-response';
import {map} from 'rxjs/operators';
import {SortParams} from '../../entities/requests/sort-params';
import {AssociationHandlerOptions} from '@kpro-software/dynamic-forms/lib/data/association-handler-options';
import {HorseSexes} from '../../enums/horse-sexes';
import {Breedingways} from '../../enums/breedingway.enum';
import {HorseInfo} from '../../entities/results/horse-info';
import {Horse} from '../../entities/horse';
import {ManageableTables} from '../../entities/response/manageable-tables';
import {ParentData} from '../../entities/response/parent-data';
import {VisibleColumnsResponse} from '../../entities/response/visible-columns-response';
import {BaseInput} from '@kpro-software/dynamic-forms/lib/ui/dynamic-form/base-input';
import {Response} from '../../entities/response/response';
import {Tables} from '../../enums/tables';
import {ControllerNames} from '../../enums/controllerNames';

@Injectable({
  providedIn: 'root'
})
export class DynamicFormsApiService {
  private listItemsSubject = new BehaviorSubject<DynamicFormsListResponse>(null);

  private listFunction = this.defaultListFunction;

  constructor(private api: DatamanagementApiService) {
  }

  public setListFunction(listFunction) {
    this.listFunction = listFunction;
  }

  public resetListFunction() {
    this.listFunction = this.defaultListFunction;
  }

  public getManageableTables(): Observable<ManageableTables> {
    return this.api.get<ManageableTables>('config/getManageableTables');
  }

  public getDescription(table: string, database = 'pedigree', mode = 'default'): Observable<BaseInput<any>[]> {
    return this.api.get('forms/getDescription', {table, database, mode});
  }

  public create(table: string, entity: any, controllerName: string, options?: { [key: string]: any }): Observable<any> {
    const request = {
      entity,
      table,
      options
    };
    return this.api.post(controllerName + '/create', request);
  }

  public createSkeletonHorse(horseInfo: HorseInfo): Observable<{ entity: Horse }> {
    const request = {
      entity: {
        name: horseInfo.name.toLowerCase(),
        blocked: 0,
        fei: horseInfo.fei,
        sex: HorseSexes.STALLION,
        breedingway: Breedingways.NATRUAL_BREEDING
      },
      table: 'horses',
      isSkeletonHorse: true
    };
    return this.api.post('dynamic-forms-crud/create', request);
  }

  public update(table: string, entity: any, controllerName: string, options?: { [key: string]: any }): Observable<any> {
    const request = {
      entity,
      table,
      options
    };
    return this.api.put(controllerName + '/update', request);
  }

  public find(table: string, id: any, controllerName: string, parentTable?: string, parentId?: number) {
    return this.api.post<EntityResponse>(controllerName + '/find', {table, id, parentTable, parentId});
  }

  public delete(table: string, id: number, controllerName: string) {
    const request = {
      table,
      id
    };
    return this.api.delete(controllerName + '/delete', request);
  }

  public defaultListFunction(
    page: number,
    table: string,
    itemsPerPage: number,
    searchWord: string,
    controllerName: string,
    sorting: SortParams,
    parentTable?: string,
    parentId?: number): Observable<DynamicFormsListResponse> {
    return this.api.post<DynamicFormsListResponse>(controllerName + '/list', {...sorting, parentTable, parentId}, {
      page,
      table,
      itemsPerPage,
      searchWord,
    });
  }

  public listItems(
    page: number,
    table: string,
    itemsPerPage: number,
    searchWord: string,
    controllerName: string,
    sorting: SortParams,
    parentTable?: string,
    parentId?: number): Observable<DynamicFormsListResponse> {
    return this.listFunction(page, table, itemsPerPage, searchWord, controllerName, sorting, parentTable, parentId)
      .pipe(
        map(value => {
          this.listItemsSubject.next(value);
          return value;
        })
      );
  }

  public searchOptions(options: AssociationHandlerOptions, database = 'pedigree') {
    return this.api.post<OptionListResponse>('dynamic-forms-crud/searchOptions', {...options, database});
  }

  public listItemsChanges(): Observable<DynamicFormsListResponse> {
    return this.listItemsSubject.asObservable();
  }

  public removeListItems() {
    this.listItemsSubject.next(null);
  }

  public setListItems(data: DynamicFormsListResponse) {
    this.listItemsSubject.next(data);
  }

  public getParentData(parentTable: string, parentId: number, table: string): Observable<{ parent: ParentData }> {
    return this.api.post('dynamic-forms-crud/get-parent-data', {parentTable, parentId, table});
  }

  public loadVisibleColumns(table: string): Observable<VisibleColumnsResponse> {
    return this.api.post('dynamic-forms-crud/load-visible-columns', {table});
  }

  public getColumns(table: string, database: string, addIdColumn = false): Observable<Response<(BaseInput<any> & {
    fieldName: string,
    input: string,
    options: Array<any>
  })[]>> {
    return this.api.get('forms/getColumns', {table, database, addIdColumn});
  }

  public getControllerName(table: string) {
    switch (table) {
      case Tables.RIDER:
        return ControllerNames.RESULTS_RIDER;
      case Tables.COMPETITION:
        return ControllerNames.COMPETITIONS;
      case Tables.EVENT:
        return ControllerNames.EVENTS;
      case Tables.TOURNEY:
        return ControllerNames.TOURNEY;
      case Tables.HORSECHANGES:
        return ControllerNames.HORSECHANGES;
      default:
        return ControllerNames.DYNAMIC_FORMS_CRUD;
    }
  }
}

