import {Injectable} from '@angular/core';
import {ApiClient} from '../api-client';
import {HttpClient} from '@angular/common/http';
import {SessionService} from '../session.service';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {LocaleService} from '../locale.service';

@Injectable({
  providedIn: 'root'
})
export class AdsApiService extends ApiClient {

  constructor(protected http: HttpClient,
              protected  session: SessionService,
              protected  router: Router,
              protected localeService: LocaleService) {
    super(http, session, router, environment.adsEndpoint, localeService);
  }
}
