import {AfterViewInit, Component} from '@angular/core';
import {AdBlockerService} from './services/ad-blocker.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  public title = 'horsetelex-general-admin';

  constructor(private adBlockerService: AdBlockerService) {
  }

  ngAfterViewInit(): void {
    this.adBlockerService.showAdBlockerInfoIfEnabled();
  }

}
