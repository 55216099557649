import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {animate, group, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-sidebar-item',
  templateUrl: './sidebar-item.component.html',
  styleUrls: ['./sidebar-item.component.scss',
  ],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        'max-height': `{{height}}px`, visibility: 'visible'
      }), {params: {height: 200}}),
      state('out', style({
        'max-height': '0px', visibility: 'hidden'
      })),
      transition('in => out', [group([
          animate('600ms ease-in-out', style({
            'max-height': '0px'
          })),
          animate('700ms ease-in-out', style({
            visibility: 'hidden'
          }))
        ]
      )]),
      transition('out => in', [group([
          animate('1ms ease-in-out', style({
            visibility: 'visible'
          })),
          animate('600ms ease-in-out', style({
            'max-height': '{{height}}px'
          })),
        ]
      )])
    ])
  ]
})
export class SidebarItemComponent implements OnInit {

  @Input()
  public set item(item: { name: string, image: string, url: string, subItems: [{ name: string, url: string }] }) {
    this._item = item;

    if (item.subItems) {
      this.height = this._item.subItems.length * 54;
      if (this.isSubelementActive()) {
        this.animationState = 'out';
      }
    }
  }

  // tslint:disable-next-line:variable-name
  public _item: { name: string, image: string, url: string, subItems: [{ name: string, url: string }] };

  @Input()
  public currentRoute: string;

  @Input()
  public isSidebarMinimized = false;

  public animationState = 'out';
  public height = 54;

  constructor(private router: Router) {
  }

  ngOnInit(): void {
    if (this.isSubelementActive()) {
      this.toggleAnimationState();
    }
  }

  navigateToUrl(url: string, isSubitem: boolean) {
    if (!this._item.subItems || isSubitem) {
      this.router.navigateByUrl(url);
    }
  }

  toggleAnimationState() {
    if (this.animationState === 'in') {
      this.animationState = 'out';
    } else {
      this.animationState = 'in';
    }
  }

  isActive(): boolean {
    if (this.currentRoute === this._item.url || (this.currentRoute === '/' && this._item.url === '')) {
      return true;
    } else {
      return this.isSubelementActive();
    }
  }

  isSubelementActive(): boolean {
    if (this._item.subItems) {
      for (const item of this._item.subItems) {
        if (this.currentRoute?.startsWith(item.url)) {
          return true;
        }
      }
    }
    return false;
  }
}
