import {Injectable} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {DynamicFormsApiService} from './datamanagement/dynamic-forms-api.service';
import {ToastrService} from 'ngx-toastr';
import {MessageBoxService} from './message-box.service';
import {RedirectPlaceholder} from '../enums/redirect-placeholder';
import {Router} from '@angular/router';
import {ManageableTable} from '../entities/response/manageable-table';
import {catchError, map} from 'rxjs/operators';
import {Observable, of} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DynamicFormsSaveService {

  constructor(
    private readonly messageBoxService: MessageBoxService,
    private readonly router: Router,
    private readonly toast: ToastrService,
    private readonly dynamicFormsApiService: DynamicFormsApiService
  ) {
  }

  public save(
    form: FormGroup,
    isEdit: boolean,
    table: ManageableTable,
    controllerName: string,
    beforeCreate: (entity: any) => Promise<boolean>,
    beforeUpdate: (entity: any) => Promise<boolean>,
    afterSaveRedirectUrl: string,
    navigateToOverviewAfterSave: boolean,
    options?: { [key: string]: any }
  ): Promise<Observable<any>> {
    form.markAllAsTouched();
    if (form.valid) {
      if (isEdit) {
        return this.updateEntity(form, table, controllerName, beforeUpdate, navigateToOverviewAfterSave, options);
      } else {
        return this.createEntity(form, table, controllerName, beforeCreate, afterSaveRedirectUrl, options);
      }
    } else {
      return of(of(false)).toPromise();
    }
  }

  private async updateEntity(
    form: FormGroup,
    table: ManageableTable,
    controllerName: string,
    beforeUpdate: (entity: any) => Promise<boolean>,
    navigateToOverviewAfterSave: boolean,
    options?: { [key: string]: any }
  ): Promise<Observable<any>> {
    const formValue = form.value;

    // BeforeCreate aufrufen
    if (beforeUpdate) {
      const isBeforeSaveValid = await beforeUpdate(form.value);

      if (!isBeforeSaveValid) {
        return;
      }
    }

    if (formValue.password === null) {
      delete formValue.password;
    }

    // Eintrag aktualisieren
    return this.dynamicFormsApiService.update(table.table, formValue, controllerName, options).pipe(
      map(value => {
        if (this.checkSaveError(value)) {
          this.toast.success($localize`:@@tsEntryInTableUpdated:Eintrag in ${table.label} aktualisiert`);

          if (navigateToOverviewAfterSave) {
            this.router.navigateByUrl(this.getRouteWithoutEdit());
          }
          return value;
        }
      }),
      catchError(error => {
        let message;
        if (error?.error?.message) {
          message = error?.error?.message;

        } else {
          message = $localize`:@@tsEntryUpdateErrorMessage:Beim Aktualisieren des Eintrags ist ein Fehler aufgetreten. Bitte prüfen Sie ihre Eingaben oder versuchen Sie es später erneut`;
        }

        this.messageBoxService.confirm(
          $localize`:@@tsEntryUpdateError:Eintrag konnte nicht in aktualisiert werden`,
          message
        ).result.subscribe();
        return of(false);
      }));
  }

  private async createEntity(
    form: FormGroup,
    table: ManageableTable,
    controllerName: string,
    beforeCreate: (entity: any) => Promise<boolean>,
    afterSaveRedirectUrl: string,
    options?: { [key: string]: any }
  ): Promise<Observable<any>> {
    // BeforeCreate aufrufen
    if (beforeCreate) {
      const isBeforeSaveValid = await beforeCreate(form.value);

      if (!isBeforeSaveValid) {
        return;
      }
    }

    const entity = form.getRawValue();

    // Id von dem Parent setzen
    if (table.parentTable && table.parentId) {
      entity[table.parentTable + '_id'] = table.parentId;
    }

    // Eintrag Speichern
    return this.dynamicFormsApiService.create(table.table, entity, controllerName, options)
      .pipe(map(value => {
          if (this.checkSaveError(value)) {
            this.toast.success($localize`:@@tsEntryInTableCreated:Eintrag in ${table.label} erstellt`);
            if (afterSaveRedirectUrl !== null) {
              this.router.navigateByUrl(this.getRouteWithoutAdd() + this.getAfterSaveRedirectUrl(value.entity.id, afterSaveRedirectUrl));
            }
          }
          return value;
        }),
        catchError(error => {
          let message = '';
          if (error?.error?.message) {
            // Wenn es eine Nachricht bei den Fehlern gibt, wird diese angezeigt.
            message = error?.error?.message;
          } else if (error?.error?.errors) {
            // Wenn es keine 'message' aber 'errors' gibt, werden die Nachrichten aus den errors angezeigt.
            for (const errorKey of Object.keys(error.error.errors)) {
              if (errorKey !== 'message') {
                for (const errorTypeKey of Object.keys(error.error.errors[errorKey])) {
                  message += error.error.errors[errorKey][errorTypeKey] + '\n';
                }
              }
            }
          } else {
            // Wenn es werder message noch errors gibt, wird diese default Nachricht angezeigt.
            message = $localize`:@@tsEntrySaveError:Beim Speichern des Eintrags ist ein Fehler aufgetreten. Bitte prüfen Sie ihre Eingaben oder versuchen Sie es später erneut`;
          }

          this.messageBoxService.confirm(
            $localize`:@@tsEntryCreatedInTableError:Eintrag konnte nicht in ${table.label} erstellt werden`,
            message
          ).result.subscribe();

          return of(false);
        }));
  }

  private checkSaveError(response: any) {
    if (response.errors) {
      const errors = [];
      Object.keys(response.errors).forEach(errorField => {
        Object.keys(response.errors[errorField]).forEach(errorKey => errors.push(response.errors[errorField][errorKey]));
      });

      this.messageBoxService.confirm(
        $localize`:@@tsEntrySaveError:Beim Speichern Ihrer Eingaben ist ein Fehler aufgetreten`,
        errors.join('\n')
      ).result.subscribe();

      return false;
    } else {
      return true;
    }
  }

  private getRouteWithoutAdd(): string {
    let urlSegments = this.router.url.split('/');
    urlSegments = urlSegments.slice(0, urlSegments.length - 1);
    return urlSegments.join('/');
  }

  private getAfterSaveRedirectUrl(id, afterSaveRedirectUrl: string) {
    return afterSaveRedirectUrl.replace(RedirectPlaceholder.ID, id);
  }

  private getRouteWithoutEdit() {
    let urlSegments = this.router.url.split('/');
    urlSegments = urlSegments.slice(0, urlSegments.length - 2);
    return urlSegments.join('/');
  }
}
