<div class="modal-header">
  <h4 class="modal-title">{{title}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('CROSS')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p style="white-space: pre-line">{{message}}</p>
  <p *ngIf="hint">
    <span class="font-weight-bold" i18n="hint: | hint: @@hint">Hinweis:</span> {{hint}}
  </p>
</div>
<div class="modal-footer">
  <button type="button" id="acceptButton" class="btn btn-outline-dark" (click)="applyChoice(true)" i18n="check | yes @@yes">Ja</button>
  <button type="button" id="declineButton" class="btn btn-outline-dark" (click)="applyChoice(false)" i18n="check | no @@no">Nein</button>
</div>
