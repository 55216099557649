import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UsernamePasswordRequest} from '../../../../entities/requests/username-password-request';

@Component({
  selector: 'app-confirm-username-password',
  templateUrl: './confirm-username-password.component.html',
  styleUrls: ['./confirm-username-password.component.scss']
})
export class ConfirmUsernamePasswordComponent implements OnInit {

  @Input()
  title: string;

  @Input()
  message: string;

  public authForm: FormGroup;

  constructor(private activeModal: NgbActiveModal, private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.authForm = this.fb.group({
      username: [null, Validators.required],
      password: [null, Validators.required],
    });
  }

  public returnData() {
    const request: UsernamePasswordRequest = {
      username: this.authForm.value.username,
      password: this.authForm.value.password
    };
    this.activeModal.close(this.authForm.value);
  }

  cancel() {
    this.activeModal.dismiss('CROSS');
  }
}
