export enum CrawlingTasksStatus {
  NEW = 'NEW',
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  CRAWLED = 'CRAWLED',
  FINISHED = 'FINISHED',
  STUCK = 'STUCK',
  ERROR = 'ERROR',
  SAVE_IN_PROGRESS = 'SAVE_IN_PROGRESS',
}
