import {Pipe, PipeTransform} from '@angular/core';
import {CrawlingTasksStatus} from '../../../enums/crawling-tasks-status.enum';

@Pipe({
  name: 'crawlingTaskStatus'
})
export class CrawlingTaskStatusPipe implements PipeTransform {

  transform(input: string): unknown {
    switch (input) {
      case CrawlingTasksStatus.NEW:
        return $localize`:@@crawlingStatusNew:Neu`;
      case CrawlingTasksStatus.CRAWLED:
        return $localize`:@@crawlingStatusCrawled:Abgeschlossen`;
      case CrawlingTasksStatus.ERROR:
        return $localize`:@@crawlingStatusError:Fehlerhaft`;
      case CrawlingTasksStatus.STUCK:
        return $localize`:@@crawlingStatusStuck:Angehalten`;
      case CrawlingTasksStatus.IN_PROGRESS:
        return $localize`:@@crawlingStatusInProgress:In Bearbeitung`;
      case CrawlingTasksStatus.PENDING:
        return $localize`:@@crawlingStatusPending:Wartend`;
      case CrawlingTasksStatus.FINISHED:
        return $localize`:@@crawlingStatusFinished:Fertig`;
      case CrawlingTasksStatus.SAVE_IN_PROGRESS:
        return $localize`:@@crawlingStatusSaveInProgress:Am Speichern`;
      default:
        return input;
    }
  }

}
